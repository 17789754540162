import * as React from 'react';
import { Box, Chip, Icon, Link, Paper, Stack, SvgIcon, Typography, sliderClasses} from '@mui/material';
import { data } from '../../data/Info';
import SkillRow from '../../components/Skill/SkillRow';
import EngineeringIcon from '@mui/icons-material/Engineering';
import MoodIcon from '@mui/icons-material/Mood';


export default function Footer() {
    return (
        <Box sx={{display: 'flex', alignContent: 'center', justifyContent: 'center', paddingBottom: "25px"}}>
            <Typography variant="caption">
                Created using MUI, source available <Link href="https://github.com/Mr-Craig/mr-craig.github.io/tree/main" target="_blank" rel="noreferrer">here</Link>.
            </Typography>
        </Box>
    );
}