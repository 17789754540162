import * as React from 'react';
import { Box, Button, IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faSquareGithub } from '@fortawesome/free-brands-svg-icons';

export interface ISocialButton {
    icon: IconDefinition, 
    name: string,
    link: string
}

export default function SocialButton(props : ISocialButton) {
    return (
        <IconButton aria-label={props.name} sx={{borderRadius:"0px", padding: "8px 15px", display: "flex", flex: "0 1 auto"}} href={props.link} target="_blank">
            <FontAwesomeIcon icon={props.icon} />    
            <Typography variant="subtitle2" color="text.secondary" component="div" sx={{padding: "0px 5px"}}>
                {props.name}
            </Typography>
        </IconButton>
    )
};