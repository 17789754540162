import * as React from 'react';
import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faSquareGithub } from '@fortawesome/free-brands-svg-icons';

export interface ISkillRow {
    name: string,
    yoe?: number,
    level: string
}

export default function SkillRow(props : ISkillRow) {
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box>
                <Typography variant='subtitle2'>{props.name}</Typography>
            </Box>
            <Box>
                <Tooltip title="" placement='top'>
                    <Typography variant='caption'>{props.level }</Typography>
                </Tooltip>
            </Box>
        </Box>
    )
};