import * as React from 'react';
import { Box, Paper,Typography } from "@mui/material";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { data } from '../../data/Info';
import Project from '../../components/Project/Project';

export default function Projects() {
    return (
        <Box>
            {/*<Paper square elevation={13} sx={{display: 'flex'}}>
                <Paper square elevation={20} sx={{display: 'flex', padding: '7px'}}>
                    <AccountTreeIcon/>
                </Paper>
                <Typography variant="subtitle2" sx={{display: 'flex', padding: '7px'}}>
                    Projects
                </Typography>
            </Paper>*/}
            {data.projects.map((project) => (<Project project={project} />))}
        </Box>
    )
};