import { faLinkedin, faSquareGithub } from "@fortawesome/free-brands-svg-icons";
import headerPicture from './pic.jpg';
import * as fs from 'fs';
import { Box, Link, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

export const data = {
    header: {
        name: "Alex Craig",
        title: "Software Engineer",
        picture: headerPicture,
        socials: [
            {
                icon: faSquareGithub,
                name: "GitHub",
                link: "https://github.com/mr-craig"
            },
            {
                icon: faLinkedin,
                name: "LinkedIn",
                link: "https://www.linkedin.com/in/alexcraig2/"
            }
        ]
    },
    sidebar: {
        aboutMe: `I am a recent graduate proficient mainly in TypeScript, JavaScript, and C++. I'm driven by a passion for developing high-quality, performant code that solves real-world problems. I'm dedicated to staying updated with the latest technologies and methodologies to ensure I deliver innovative solutions.`,
        skills: [
            {
                yoe: 2017,
                level: "Proficient",
                name: "C++"
            },
            {
                yoe: 2018,
                level: "Proficient",
                name: "JavaScript"
            },
            {
                yoe: 2021,
                level: "Proficient",
                name: "TypeScript"
            },
            {
                yoe: 2021,
                level: "Proficient",
                name: "Unreal Engine"
            },
            {
                yoe: 2021,
                level: "Proficient",
                name: "Ionic Framework"
            },
            {
                yoe: 2021,
                level: "Proficient",
                name: "React"
            },
            {
                yoe: 2021,
                level: "Familiar",
                name: "Marko"
            },
            {
                level: "Familiar",
                name: "C#"
            },
            {
                level: "Familiar",
                name: "PHP"
            },
            {
                level: "Familiar",
                name: "Java"
            },
            {
                level: "Familiar",
                name: "Swift"
            },
            {
                level: "Familiar",
                name: "Lua"
            }
        ]
    },
    projects: [
        {
            name: 'Vulkan Renderer',
            startDate: 'Mar 2024',
            endDate: undefined,
            slides: [
                require('./projects/cpr/1.png'),
                require('./projects/cpr/2.png'),
                require('./projects/cpr/3.png'),
                require('./projects/cpr/4.png'),
            ],
            description: (
                <Box>
                    <Typography variant="body2">
                        After my making my past two games, I decided I wanted to try and make a small game engine/renderer using Vulkan so I could further understand the underlying mechanisms of how different game engines work. I decided on using Vulkan as it's a very low-level API for graphics and computing so it would give me better insight into how the GPU pipeline works. 
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        It was completely written in C++ and uses Vulkan for rendering, GLFW for cross-platform windows, PhysX for physics and ImGUI for a basic editor GUI.
                    </Typography>
                    <Typography variant="subtitle2" sx={{marginTop: "5px"}}>
                        Features:
                    </Typography>
                    <ul style={{marginTop: "0px"}}>
                        <li>Multi-threaded rendering and physics using Vulkan and PhysX</li>
                        <li>Runtime shader compilation from GLSL to SPIR-V</li>
                        <li>Compute shader support</li>
                        <li>Asset Registry, allowing easy access to import different assets such as Textures (with Mipmap Generation) and Meshes</li>
                        <li>Custom memory allocator for all Vulkan resources</li>
                        <li>Forward Renderer using Blinn-Phong shading with a Material System</li>
                        <li>Cascaded Shadow Mapping for Directional Lights</li>
                        <li>Editor built using ImGUI for easy scene management</li>
                    </ul>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        This project is still ongoing and I would like to add more features to it such as Point Shadows, Deferred Rendering, PBR, Skeletal Animation (Compute Shader) and Automatic Instancing.
                    </Typography>
                </Box>
            ),
            technologies: ["C++", "Vulkan", "PhysX", "GLFW", "ImGUI", "Assimp", "GLSL", "Nsight Graphics", "RenderDoc"],
            externalLink: {
                name: "GitHub",
                to: "https://github.com/mr-craig/cpr"
            }
        },
        {
            name: 'Cocoa Conquest',
            startDate: 'Dec 2023',
            endDate: 'Dec 2023',
            slides: [
                require('./projects/cc/1.jpg'),
                require('./projects/cc/2.png'),
                require('./projects/cc/3.png'),
                require('./projects/cc/4.png'),
                require('./projects/cc/5.png'),
                require('./projects/cc/6.png'),
                `<iframe src="https://www.youtube-nocookie.com/embed/1f5FSpfzoXg?si=EfCaILa2InUEV4Nz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
            ],
            description: (
                <Box>
                    <Typography variant="body2">
                        This game was created in 72 hours for the Winter MelonJam 2023 game jam. Our team was only 2 people and all the games programming, UI, some of the art and level design was all done by me.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        The theme of the jam was <i>Charm</i>, we decided to create a 3d physics based platformer in which you control a marshmallow who needs to traverse a cup of hot chocolate. You need to collect charms which gives you more movement abilities in order to get the key to Choccy's heart.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        The game was built using Unreal Engine 5.3, it was almost fully programmed using Blueprints with the UI being created using UMG. A small part was coded in C++ as I needed to add some functionaility to the game which wasn't available using Blueprints.
                    </Typography>
                </Box>
            ),
            technologies: ["Unreal Engine", "Blueprints", "UMG", "Blender", "C++"],
            externalLink: {
                name: "Itch.io",
                to: "https://claigo.itch.io/cocoa-conquest"
            }
        },
        {
            name: 'E2EE Chat Application',
            startDate: 'Nov 2022',
            endDate: 'Mar 2023',
            slides: [
                require('./projects/e2ee/1.png'),
                require('./projects/e2ee/2.png'),
                require('./projects/e2ee/3.png'),
                require('./projects/e2ee/4.png'),
                require('./projects/e2ee/5.png'),
                require('./projects/e2ee/6.png'),
                `<iframe src="https://www.youtube.com/embed/aNsLN_IpWtU?si=Zyb90NhmchYY0X9b" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
            ],
            description: (
                <Box>
                    <Typography variant="body2">
                        During my final year of university I was tasked to solve a problem and then design, implement and evaluate a solution to that problem. I decided to try and solve the issue of censorship and privacy that is becoming an issue in some countries relating to instant-messaging. The solution was to create a regular end-to-end encrypted chat application and then allow users to create and manage their own servers in order to prevent governments from fully being able to shutdown the use of the app.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        The front-end of the application was written in TypeScript and uses Ionic Framework for the UI, React, and Capacitor to allow the application to be run natively. The backend for the app was written completely in C++ and uses <Link href="https://github.com/uNetworking/uWebSockets" target="_blank"  rel="noopener noreferrer">uWebSockets</Link> which is a high-performant library for managing WebSockets.
                    </Typography>
                    <Typography variant="subtitle2" sx={{marginTop: "5px"}}>
                        Features:
                    </Typography>
                    <ul style={{marginTop: "0px"}}>
                        <li>Frontend Application that can be ran on the Web, as a PWA, or natively on iOS and Android</li>
                        <li>Backend can be compiled successfully for Windows, macOS and Linux</li>
                        <li>Using a Free-Tier AWS server (t2.micro) the backend can handle hundreds of simultaneous connection</li>
                        <li>Passwordless Authentication using <Link href="https://webauthn.guide/" target="_blank" rel="noopener noreferrer">WebAuthN</Link></li>
                        <li>Offline Notification using <Link href="https://web.dev/articles/push-notifications-web-push-protocol" target="_blank" rel="noopener noreferrer">WebPush</Link> API</li>
                        <li>All Messages end-to-end encrypted using ECDH on the NIST P-256 curve and AES-256-GCM</li>
                    </ul>
                </Box>
            ),
            technologies: ["C++", "TypeScript", "Ionic Framework", "React", "Capacitor", "CMake", "uWebSockets", "OpenSSL", "SQLite"],
            externalLink: {
                name: "GitHub",
                to: "https://github.com/Mr-Craig/ee2e-chat-app"
            }
        },
        {
            name: 'Ball Game',
            startDate: 'Nov 2021',
            endDate: undefined,
            slides: [
                require('./projects/ballgame/1.jpg'),
                require('./projects/ballgame/2.jpg'),
                require('./projects/ballgame/3.jpg'),
                require('./projects/ballgame/4.jpg'),
                require('./projects/ballgame/5.jpg'),
                require('./projects/ballgame/6.jpg'),
            ],
            description: (
                <Box>
                    <Typography variant="body2">
                        Ball Game is a hobby project that I have been developing slowly over the last few years during my time at university. it is a 3D physics platformer where you are controlling a ball and need to finish the levels in the quickest time possible without dying.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        It was originally made in Unreal Engine 4.27 but has since been upgraded to 5.1, the games core systems are all made in C++ with some gameplay mechanics being created in Blueprints. UMG was used for the majority of the UI but Slate has been used for the Workshop plugin for the Unreal Editor.
                    </Typography>
                    <Typography variant="subtitle2" sx={{marginTop: "5px"}}>
                        Features:
                    </Typography>
                    <ul style={{marginTop: "0px"}}>
                        <li>Fully replicated multiplayer using Steam</li>
                        <li>Utilizes Async Physics; released a <Link href="https://github.com/Mr-Craig/AsyncTickPhysics" target="_blank">plugin</Link> to allow easy access to the physics thread.</li>
                        <li>Full Steamworks integration including Leaderboards, Statistics/Achievements, Inventory System and Workshop.</li>
                        <li>Editor Plugin to allow users to upload maps to the Steam Workshop</li>
                        <li>Runtime Pak Loading to allow custom maps created by players with leaderboards and multiplayer.</li>
                        <li>Voice Chat using Epic Online Services</li>
                        <li>Custom Replay system that uploads user replays when a score is submitted to the leaderboards, which can be viewed across different game versions.</li>
                        <li>Infinite Map Generation in the Infinite Run mode.</li>
                    </ul>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        Backend was created using JavaScript and allows users to submit to the leaderboards, view replays and purchase items. The game is due to be released later this year on Steam but is available using Steam Playtest right now.
                    </Typography>
                </Box>
            ),
            technologies: ["Unreal Engine", "C++", "Blueprints", "UMG", "Blender", "Slate", "Steamworks", "Epic Online Services", "JavaScript", "Node.js", "Express.js"],
            externalLink: {
                name: "Steam",
                to: "https://store.steampowered.com/app/1797270/Ball_Game/"
            }
        },
        {
            name: 'Pomodoro Timer',
            startDate: 'Nov 2021',
            endDate: 'Mar 2022',
            slides: [
                require('./projects/pomodoro/1.png'),
                require('./projects/pomodoro/2.png'),
                require('./projects/pomodoro/3.png'),
                require('./projects/pomodoro/4.png'),
                require('./projects/pomodoro/5.png'),
                require('./projects/pomodoro/6.png'),
                require('./projects/pomodoro/7.png')
            ],
            description: (
                <Box>
                    <Typography variant="body2">
                        For my second year of university I was assigned a group project to create a piece of software that achieves one of the UNs <Link href="https://sdgs.un.org/goals" target="_blank">SDGs</Link>.
                        Our group decided to make a timer to help working professionals and students to work more efficiently using the <Link href="https://en.wikipedia.org/wiki/Pomodoro_Technique" target="_blank">pomodoro technique</Link>.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        My role was primarily focused on the backend of the site and some frontend work, the backend was created using Node.js and Express.js with MySQL as the database. As this was a group project and we were on a fixed deadline we used SCRUM to ensure that the project ran on-time, the entire project was completed over 2 sprints.
                    </Typography>
                    <Typography variant="subtitle2" sx={{marginTop: "5px"}}>
                        Features:
                    </Typography>
                    <ul style={{marginTop: "0px"}}>
                        <li>Customizable pomodoro timer so you can tweak it to your liking.</li>
                        <li>Analytics with a leaderboard for everyone using the site.</li>
                        <li>All settings and to-do lists persists through sessions and is stored in the database.</li>
                        <li>Easy registration and login using Google.</li>
                    </ul>
                </Box>
            ),
            technologies: ["JavaScript", "Node.js", "MySQL", "Express.js", "Tailwind"],
            externalLink: {
                name: "GitHub",
                to: "https://github.com/Mr-Craig/pomodoro-year-2-project"
            }
        },
        {
            name: 'TheKeyValley.com',
            startDate: 'Feb 2021',
            endDate: 'Feb 2023',
            slides: [
                require('./projects/kv/1.png'),
                require('./projects/kv/2.png'),
                require('./projects/kv/3.png'),
                require('./projects/kv/4.png'),
                require('./projects/kv/5.png'),
                require('./projects/kv/6.png')
            ],
            description: (
                <Box>
                    <Typography variant="body2">
                        TheKeyValley.com was a E-commerce business that was primarily focused on selling digital items through eBay. In order to improve customer satisfaction and ease-of-use I created a fully automated just-in-time delivery system for these items.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        When a customer places an order the system first purchases the key from one our supplier and will then send an e-mail to the user with a link to claim the item that they've purchased. This process would on average take about 20 seconds after the payment had been confirmed by eBay.
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: "5px"}}>
                        This allowed the business to run without any stock required upfront and allowed for hundreds of items to be listed without one item having to be purchased beforehand. This system worked very well allowing the eBay account to have 99.9% positive feedback and a high score on trustpilot with a high repeat customer rate. 
                    </Typography>
                    <Typography variant="body2" sx={{marginTop:"5px"}}>
                        Before the automated system all orders were manually purchased by me and sent to the customer, since digital items is very high-risk for fraud I was experiencing alot of chargebacks and ended up losing lots of profit due to this. After the system was created, over <strong>90%</strong> of fraudulent orders were prevented access to the key with a low false-positive rate of 5% which required me to manually verify the order if this happened.
                    </Typography>
                    <Typography variant="subtitle2" sx={{marginTop: "5px"}}>
                        Features:
                    </Typography>
                    <ul style={{marginTop: "0px"}}>
                        <li>Automatic just-in-time delivery for digital items, using shopify or eBay.</li>
                        <li>Fast and easy claim process for customers.</li>
                        <li>Fraud mitigation techniques such as device fingerprinting, ip reputation and phone verification.</li>
                        <li>Order management and reports generated automatically.</li>
                        <li>Stock tracking and automatic price adjustment based on the current market price for the item.</li>
                        <li>Ability to add multiple suppliers and picks the cheapest item to purchase.</li>
                        <li>Ability to list items on both our eBay and Shopify store within the admin panel and connect them to the suppliers.</li>
                        <li>Key backlog so keys can be resold if they have been purchased fraudulently.</li>
                    </ul>
                    <Typography variant="body2" sx={{marginTop:"5px"}}>
                        The entire system was created by me, it was built using Node.js, <Link href="https://markojs.com/" target="_blank">Marko</Link>, and MySQL.
                    </Typography>
                </Box>
            ),
            technologies: ["JavaScript", "Node.js", "MySQL", "Express.js", "Marko", "eBay API"],
            externalLink: undefined
        },
    ]
};

