import * as React from 'react';
import Container from '@mui/material/Container';
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Header from './layout/top/Header';
import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import Sidebar from './layout/right/Sidebar';
import Projects from './pages/projects/Projects';
import Footer from './layout/bottom/Footer';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Projects/>,
  },
]);

export default function App() {
  const theme = useTheme();
  const smallBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Container maxWidth="lg">
      <Header/>
      <Grid container spacing={0} sx={{flexDirection: smallBreakpoint ? "row" : "column-reverse"}}>
        <Grid sm={8} xs={12} sx={{padding: smallBreakpoint ? "0px 10px 0px 0px" : "10px 0px"}}>
          <RouterProvider router={router} />
        </Grid>
        <Grid sm={4} xs={12}>
          <Sidebar/>
        </Grid>
      </Grid>
      <Footer/>
    </Container>
  );
}
