import * as React from 'react';
import { AppBar, Box, Button, Dialog, Grid, IconButton, Toolbar, Tooltip, Typography, useMediaQuery } from "@mui/material";

import './ProjectSlide.css';
import theme from '../../../theme';
import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

export interface IProjectSlide {
    in: string
}

export default function ProjectSlide(props : IProjectSlide) {
    const smallBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const [open, setOpen] = useState(false);
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <Box sx={{width:"100%"}}>
                {props.in.includes("iframe") ? (<div style={{width:"100%"}} dangerouslySetInnerHTML={{__html: props.in}}></div>) : (<img onClick={() => {
                    setOpen(true);
                }} src={props.in} width="100%"/>)}
            </Box>
            <Dialog fullScreen open={open}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar sx={{display:'flex', justifyContent: 'right'}}>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box sx={{display:'flex', height: "100%", alignContent: "center", flexDirection: "column", justifyContent:"center", alignItems: "center"}}>
                    <img src={props.in} style={{aspectRatio: 16/9, maxHeight:"800px", maxWidth: "1422.224px", width:"100%"}} loading="lazy"/>
                </Box>
            </Dialog>
        </Box>
    )
};