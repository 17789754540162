import * as React from 'react';
import { Box, Chip, Icon, Paper, Stack, SvgIcon, Typography, sliderClasses} from '@mui/material';
import { data } from '../../data/Info';
import { useMemo } from 'react';
import SkillRow from '../../components/Skill/SkillRow';
import EngineeringIcon from '@mui/icons-material/Engineering';
import MoodIcon from '@mui/icons-material/Mood';


export default function Sidebar() {
    return (
        <Box>
            <Paper sx={{display: 'flex', flexDirection: "column"}} square elevation={3}>
                <Paper square elevation={13} sx={{display: 'flex'}}>
                    <Paper square elevation={20} sx={{display: 'flex', padding: '7px'}}>
                        <MoodIcon/>
                    </Paper>
                    <Typography variant="button" sx={{display: 'flex', padding: '7px'}}>
                        About Me
                    </Typography>
                </Paper>
                <Typography sx={{padding: "7px"}} variant="body2">
                    {data.sidebar.aboutMe}
                </Typography>
            </Paper>
            <Paper sx={{display: 'flex', flexDirection: "column", marginTop: "10px"}} square elevation={3}>
                <Paper square elevation={13} sx={{display: 'flex'}}>
                    <Paper square elevation={20} sx={{display: 'flex', padding: '7px'}}>
                        <EngineeringIcon/>
                    </Paper>
                    <Typography variant="button" sx={{display: 'flex', padding: '7px'}}>
                        Skills
                    </Typography>
                </Paper>
                <Box sx={{padding: "5px"}}>
                    <Stack>
                        {data.sidebar.skills.map((skill) => {
                            return (<SkillRow level={skill.level} name={skill.name} yoe={skill.yoe}/>);
                        })}
                    </Stack>
                </Box>
            </Paper>
        </Box>
    );
}