import * as React from 'react';
import { Box, Button, Chip, Grid, IconButton, Paper, Typography } from "@mui/material";
import Carousel from 'react-material-ui-carousel';
import ProjectSlide from './ProjectSlide/ProjectSlide';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export interface IProject {
    project: any
}

export default function Project(props : IProject) {
    return (
        <Paper elevation={3} sx={{marginBottom: "25px"}} square>
            <Paper elevation={12} sx={{padding: "10px"}} square>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box>
                        <Typography variant='h6'>
                            {props.project.name}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='subtitle2'>
                            {props.project.startDate === props.project.endDate ? props.project.startDate : `${props.project.startDate} - ${typeof(props.project.endDate) === "undefined" ? 'Present' : props.project.endDate}`}
                        </Typography>
                    </Box>
                </Box>
            </Paper>
            <Paper elevation={15} square>
                <Carousel autoPlay={false} navButtonsAlwaysVisible={true} indicatorContainerProps={{style:{marginTop:"0px", marginBottom:"5px"}}}>
                    {props.project.slides.map((slide : string) => (<ProjectSlide in={slide}/>))}
                </Carousel>
            </Paper>
            <Typography sx={{padding:"10px"}} variant="body2">
                {props.project.description}
            </Typography>
            <Box sx={{padding:"5px"}}>
                {props.project.technologies.map((technology : string) => (<Chip sx={{margin:"5px"}} label={technology}></Chip>))}
            </Box>
            {typeof(props.project.externalLink) !== "undefined" && (
                <Paper elevation={13} sx={{marginTop: "10px", padding:"10px"}} square>
                    <Typography>
                        <Button color="info" href={props.project.externalLink.to} target="_blank" rel="noopener noreferrer" variant="text">
                            <OpenInNewIcon style={{marginRight:"5px"}}/>
                            View on {props.project.externalLink.name}
                        </Button>
                    </Typography>
                </Paper>
            )}
        </Paper>
    )
};