import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Avatar, Paper, useMediaQuery } from '@mui/material';
import green from '@mui/material/colors/green';
import SocialButton from '../../components/Social/SocialButton';

import { data } from '../../data/Info';

export default function Header() {
    const shouldCenter = useMediaQuery('(max-width:398px)');

    // flexbox hell
    return (
        <Paper sx={{display: 'flex', margin: "10px 0px", flexDirection: "column"}} square elevation={3}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: "wrap" }}>
                <Box sx={{ display: 'flex',flex: shouldCenter ? '1 0 auto' : '0 0 auto', alignItems: 'stretch', flexDirection: 'column', justifyContent:'center'}}>
                    <Paper square elevation={20} sx={{padding: "30px", display: 'flex', justifyContent: 'center'}}>
                        <Avatar sx={{ width: 86, height: 86,bgcolor: green[500], boxShadow: 10 }} alt="Avatar">AC</Avatar>
                    </Paper>
                </Box>
                <Box sx={{ flex: '1 0 auto', display: "flex", alignContent: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Box sx={{display: "flex", flex: '1 0 auto', flexDirection: "column", justifyContent: "center"}}>
                        <Typography component="div" variant="h5" sx={{padding: "10px"}} style={shouldCenter ? {textAlign: 'center'} : {}}>
                            {data.header.name}
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                {data.header.title}
                            </Typography>
                        </Typography>
                    </Box>
                    <Box sx={{display: "flex", flexWrap: "wrap"}}>
                        <Paper sx={{display: "flex", flex: "1 0 auto", flexWrap: "wrap", flexDirection: "row", justifyContent: shouldCenter ? 'space-around' : 'normal'}} square elevation={20}>
                            {data.header.socials.map((button) => {
                                return (<SocialButton icon={button.icon} name={button.name} link={button.link} />);
                            })}
                        </Paper>
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
}